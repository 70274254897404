import React from "react";
import useLocalization from "../hooks/useLocalization";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import InfoSection from "../components/privacy-policy/InfoSection";
import "../assets/scss/terms-of-use/index.scss";

const PgPP = () => {
  const currentLocale = useLocalization() || "us";

  return (
    <Layout bgHeader="#fff">
      <Seo
        title="Privacy Policy | AMZScout"
        description="This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site. This Privacy Policy governs our data collection, processing and retention practices. It also describes your choices regarding use, access and correction of your personal information."
        page="privacy-policy"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <InfoSection currentLocale={currentLocale} />
    </Layout>
  );
};

export default PgPP;
